import React from 'react';
import { PropTypes } from 'prop-types';
import { Offices } from '../../../offices';
import { InquirySection } from '../../../inquiry';
import { FormattedMessage } from 'react-intl';
import * as UI from '../../../ui';

import Archive from '../Archive';

const { DynamicContainer, ContainerType, Title, TitleTypes, Text, TextTypes } = UI;

const ArchivePage = ({ rules }) => {
    const sectionRef = React.useRef();

    return (
        <>
            <DynamicContainer as={ContainerType.SECTION} isFluid>
                <Text type={TextTypes.SECTION}>
                    <FormattedMessage id="archive.sectionTitle" />
                </Text>
                <Title
                    reference={sectionRef}
                    type={TitleTypes.H1_ITALIC}
                    extend={{ title: rules.title }}
                    as={TitleTypes.H1}
                >
                    <FormattedMessage id="archive.tile" />
                </Title>
                <Archive sectionRef={sectionRef} />
            </DynamicContainer>
            <Offices />
            <InquirySection />
        </>
    );
};

ArchivePage.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default ArchivePage;
