import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as UI from '../../../../ui';

const { Title, Text, LocalizedLink } = UI;

const ArchiveItem = ({ styles, rules, title, description, link }) => (
    <LocalizedLink to={link} className={styles.container}>
        <Title extend={{ title: rules.title }}>
            <FormattedMessage id={title} />
        </Title>
        <Text extend={{ text: rules.text }}>
            <FormattedMessage id={description} />
        </Text>
        <Text extend={{ text: rules.arrow }}>{'>'}</Text>
    </LocalizedLink>
);

ArchiveItem.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    rules: PropTypes.shape().isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default ArchiveItem;
