import React, { useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';

import * as UI from '../../../ui';
import { scrollToRef } from '../../../../utils';

import { content, paginate } from './content';

import Navigation from '../Navigation';

import ArchiveItem from './ArchiveItem';

const { DynamicContainer } = UI;

const pageSize = 6;

const Archive = ({ rules, styles, sectionRef }) => {
    const [activePage, setActivePage] = React.useState(1);
    const [opacity, setOpacity] = React.useState(1);
    const { locale } = useIntl();

    const searchParams = useMemo(
        () => new URLSearchParams(typeof window !== 'undefined' ? window.location.search : ''),
        [],
    );

    useEffect(() => {
        const page = searchParams.get('page');
        if (page) {
            setActivePage(parseInt(page, 10));
        }
    }, [searchParams]);

    const handleClick = id => {
        setOpacity(0);
        setActivePage(id);
        scrollToRef(sectionRef);

        if (typeof window !== 'undefined') {
            searchParams.set('page', id);
            window.history.pushState(null, '', `?${searchParams.toString()}`);
        }

        setTimeout(() => {
            setOpacity(1);
        }, 600);
    };

    const totalCount = content(locale).length;
    const totalPages = Math.ceil(totalCount / pageSize);

    const pageIds = Array.from(Array(totalPages).keys(), x => x + 1);

    const activePageItems = paginate({
        content: content(locale),
        pageSize,
        pageNumber: activePage,
    });

    return (
        <DynamicContainer extend={{ container: rules.container }}>
            <div className={styles.opacityContainer} style={{ opacity }}>
                {activePageItems.map(item => (
                    <ArchiveItem key={item.id} title={item.title} description={item.description} link={item.link} />
                ))}
            </div>
            <Navigation pageIds={pageIds} activeId={activePage} onClick={handleClick} />
        </DynamicContainer>
    );
};

Archive.propTypes = {
    rules: PropTypes.shape().isRequired,
    styles: PropTypes.shape().isRequired,
    sectionRef: PropTypes.shape().isRequired,
};

export default Archive;
