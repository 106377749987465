import { routes } from 'config/routes';
import type { Locale } from 'constants/intl';
import type { MessageKey } from 'modules/intl';

type ReferenceId = keyof typeof routes.references;

export interface ArchiveItem {
    title: MessageKey;
    description: MessageKey;
    link: (typeof routes.references)[ReferenceId];
    id: string;
}

const items = {
    cs: [
        'weshare',
        'cosmo',
        'moneyStep',
        'dpnk',
        'equa',
        'tmobile',
        'hi5',
        'skodamoped',
        'samsung',
        'ventusky',
        'simplifa',
        'criticalStates',
        'babysitting',
        'ethereum',
        'oto',
        'rekola',
        'grenke',
        'tamtam',
        'tapito',
        'fakturoid',
        'carolina',
    ],
    en: [
        'weshare',
        'cosmo',
        'moneyStep',
        'criticalStates',
        'kolofix',
        'equa',
        'tmobile',
        'bazos',
        'hi5',
        'skodamoped',
        'samsung',
        'ventusky',
        'simplifa',
        'babysitting',
        'ethereum',
        'oto',
        'rekola',
        'grenke',
        'tamtam',
        'tapito',
        'fakturoid',
        'carolina',
    ],
    de: [
        'cosmo',
        'deliveryhero',
        'zonky',
        'tapito',
        'oto',
        'angelcam',
        'praguer',
        'kitaportfolio',
        'skodamoped',
        'tamtam',
        'pocketFennec',
        'cooljam',
        'easyRedmine',
    ],
} as const satisfies Record<Locale, ReferenceId[]>;

export const paginate = ({
    content,
    pageSize,
    pageNumber,
}: {
    content: string[];
    pageSize: number;
    pageNumber: number;
}) => content.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

export const content = (locale: Locale): ArchiveItem[] =>
    items[locale].map(id => ({
        title: `references.${id}.title`,
        description: `references.${id}.description`,
        link: routes.references[id],
        id,
    }));
