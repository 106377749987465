import React from 'react';

import { Layout } from '../../modules/ui';
import ArchivePage from '../../modules/archive-page/components/ArchivePage';
import Seo from '../../components/SEO';
import * as Constants from '../../constants';

const Archive = () => {
    return (
        <>
            <Seo pageId={Constants.pages.REFERENCES_ARCHIVE} />
            <Layout>
                <ArchivePage />
            </Layout>
        </>
    );
};

export default Archive;
