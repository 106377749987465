export const container = ({ theme: { colors } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    position: 'relative',

    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',

    borderTop: `1px solid ${colors.lightGrey}`,

    textDecoration: 'none',

    tablet: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    ':last-of-type': {
        borderBottom: `1px solid ${colors.lightGrey}`,
    },

    ':hover > *': {
        color: colors.blue,
    },
});

const transition = {
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
};

export const title = () => ({
    ...transition,

    fontSize: '2rem',

    tablet: {
        width: '23rem',
    },
});

export const text = () => ({
    ...transition,

    fontSize: '1.125rem',
    tablet: {
        paddingLeft: '6rem',
        paddingRight: '3rem',
    },
});

export const arrow = ({ theme: { colors } }) => ({
    ...transition,

    color: colors.blue,
    fontSize: '3rem',
    marginTop: '1rem',

    tablet: {
        position: 'absolute',
        right: 0,
        marginTop: 0,
    },
});
