export const container = () => ({
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    marginTop: '3rem',
});

const buttonBase = {
    fontSize: '1.125rem',
    fontWeight: 'bolder',
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
};

export const button = ({ theme: { colors } }) => ({
    ...buttonBase,

    ':hover': {
        color: colors.fuchsia,
    },
});

export const buttonActive = ({ theme: { colors } }) => ({
    ...buttonBase,
    color: colors.fuchsia,
});

export const divider = ({ theme: { colors } }) => ({
    height: 1,
    width: 13,
    backgroundColor: colors.lightGrey,
    marginLeft: 6,
    marginRight: 6,
});
