import React from 'react';
import { PropTypes } from 'prop-types';

const Navigation = ({ styles, pageIds, activeId, onClick }) => (
    <div className={styles.container}>
        {pageIds.map(pageId => {
            const handleClick = () => onClick(pageId);

            return (
                <React.Fragment key={pageId}>
                    <button
                        className={pageId === activeId ? styles.buttonActive : styles.button}
                        type="button"
                        onClick={handleClick}
                    >
                        {pageId}
                    </button>
                    {pageId < pageIds.length && <div className={styles.divider} />}
                </React.Fragment>
            );
        })}
    </div>
);

Navigation.propTypes = {
    styles: PropTypes.shape().isRequired,
    pageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onClick: PropTypes.func.isRequired,
    activeId: PropTypes.number.isRequired,
};

export default Navigation;
